<script setup lang="ts">
const showModal = ref(false)
const titlePath = ref('')

function toggleModal() {
  showModal.value = !showModal.value
}

function updateTitlePath(newPath: string) {
  titlePath.value = newPath
}
</script>

<template>
  <div>
    <button
      class="border-b border-black text-sm no-underline"
      @click="toggleModal()"
    >
      {{ $t('base.login.button') }}
    </button>
    <LazyUiModal v-model="showModal" :title-path="titlePath" closeable>
      <LazyLogin
        in-popup
        @update:title-path="updateTitlePath"
        @close-popup="toggleModal"
      />
    </LazyUiModal>
  </div>
</template>
